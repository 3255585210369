import { detect } from 'detect-browser';
let browser = detect();

export const optimizeImage = (imageUrl, imageFormatValue, imageQualityValue) => {
    let imageFormat;
    let imageQuality;
    if(browser) {
        switch (browser.name) {
            case 'safari':
                imageFormat = imageFormatValue ? imageFormatValue : 'png';
                imageQuality = imageQualityValue ? imageQualityValue : 85;
                break;
            case 'ios':
                imageFormat = imageFormatValue ? imageFormatValue : 'png';
                imageQuality = imageQualityValue ? imageQualityValue : 85;
                break;
            case 'chrome':
                imageFormat = imageFormatValue ? imageFormatValue : "avif";
                imageQuality = imageQualityValue ? imageQualityValue : 85;
                break;
            default:
                imageFormat = imageFormatValue ? imageFormatValue : 'webp';
                imageQuality = imageQualityValue ? imageQualityValue : 75;
                break;
        }
    }
    if((imageUrl !== undefined) && (imageUrl !== null) && (imageUrl.includes('images.ctfassets.net')) && (imageUrl.includes('fm=webp') == false) && (imageUrl.includes('.svg') == false)) {
        if(imageUrl?.includes('https')) {
            if(imageUrl?.includes('?') ) {
                imageUrl = imageUrl + `&fm=${imageFormat}` + `&q=${imageQuality}`;
            }
            else {
                imageUrl = imageUrl + `?fm=${imageFormat}` + `&q=${imageQuality}`;
            }
        }
        else {
            imageUrl = 'https:' + imageUrl
            if(imageUrl?.includes('?')) {
                imageUrl = imageUrl + `&fm=${imageFormat}` + `&q=${imageQuality}`;
            }
            else {
                imageUrl = imageUrl + `?fm=${imageFormat}` + `&q=${imageQuality}`;
            }
        }
    }
    return imageUrl;
}